<!-- (c) 智汇考题 -->
<template>
    <div id="content" class="container">
        <nav-header :title="header_title"></nav-header>
        <div class="filter-box">
            <div class="select" @click="showPicker()">
                <img
                    class="icon"
                    src="../../../assets/img/select.png"
                />
                <div class="label">筛选：</div>
                <div class="value">{{ cid1_word }} {{ cid2_word }}</div>
            </div>  
        </div>
        <template v-if="loading && list.length == 0">
            <skeletonPaper></skeletonPaper>
        </template>
        <template v-else-if="list.length > 0">
            <div class="paper">
                <div class="paper-item" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
                    <div class="paper-item-comp">
                        <div class="isenter" v-if="item.userHaveJoined">已参考</div>
                        <div class="line1">
                            <img src="../../../assets/img/exam.png" alt="">
                            <div class="title">{{ item.title }}</div>
                        </div>
                        <div class="line2">
                            <div class="word">总分{{ item.score }}分</div>
                            <div class="number">共{{ item.questionCount }}道题</div>
                        </div>
                        <div class="lock" v-if="!item.userCanJoin">
                            <img class="" src="../../../assets/img/paper-lock.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="drop">
                    <img v-show="loading && !over" src="../../../assets/img/loading.gif" />
                    <span v-if="over">已经到底了</span>
                </div>
            </div>           
        </template>
        <template v-else-if="list.length == 0">
            <div class="none">
                <none></none>
            </div>
        </template>
        <div class="picker" v-if="show_picker">
            <van-popup v-model="show_picker" position="bottom">
                <van-picker show-toolbar :columns="columns" @cancel="onCancel" @confirm="onConfirm" @change="onChange" />
            </van-popup>
        </div>
        
    </div>
</template>
<script>
import Vue from 'vue';
import { Picker } from 'vant-green';
import { Popup } from 'vant-green';
Vue.use(Popup);
Vue.use(Picker);

import NavHeader from "../../../components/nav-header.vue";
import SkeletonPaper from '../../../components/skeleton/skeletonPaper';
import None from "../../../components/none.vue";

export default {
    components: {
        NavHeader,
        SkeletonPaper,
        None
    },
    data() {
        return {
            categoryFirst: true,
            header_title: '在线考试',
            cid1_word: '全部',
            cid2_word: '',
            show_picker: false,
            columns: '',
            pagination: {
                page: 1,
                size: 10,
                cid1: this.$route.query.cid || 0,
                cid2: this.$route.query.child || 0,
            },
            over: false,
            loading: false,
            categoriesData: '',
            resCategoriesData: '',
            list: [],
            vid1: 0,
            vid2: 0
        }
    },
    beforeRouteLeave(to, from, next) {
        if (from.meta.keepAlive) {
            // 只针对 keep-alive 的页面记录滚动位置
            let $content = document.querySelector('#content'); // 列表的外层容器
            let scrollTop = $content ? $content.scrollTop : 0;
            from.meta.scrollTop = scrollTop;
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'AnswerExamsDetail') {
            to.meta.useCache = true;
        }else {
            to.meta.useCache = false;
        }
        next()
    },
    activated() { 
        if(!this.$route.meta.useCache){
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
            Object.assign(this.$data, this.$options.data.call(this));
            this.getCategoryList();
            this.getData();
        }else{
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        }
        let scrollTop = this.$route.meta.scrollTop;
        if (scrollTop && scrollTop !== 0) {
            // 初次进入页面不执行，返回页面执行
            let $content = document.querySelector('#content');
            if ($content) {
                this.$nextTick(() => {
                $content.scrollTop = scrollTop;
                })
            }
        }
    },
    // mounted() {
    //     window.addEventListener("scroll", this.ScrollToBottomEvt, true);
    // },
    // beforeDestroy() {
    //     window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    // },
    deactivated() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    methods: {
        onChange(picker, values) {
            picker.setColumnValues(1, this.categoriesData[values[0]]);
        },
        onCancel() {
            this.show_picker = false;
        },
        onConfirm(picker, values) {
            console.log(this.resCategoriesData)
            console.log(values)
            this.vid1 = values[0];
            this.vid2 = values[1];
            this.pagination.cid1 = this.resCategoriesData[values[0]].id || 0;
            this.pagination.cid2 = this.resCategoriesData[values[0]].children[values[1]] ? this.resCategoriesData[values[0]].children[values[1]].id : 0;
            this.show_picker = false;
            this.resetData();
            this.getData();
            this.cid1_word = this.resCategoriesData[values[0]].name;
            this.cid2_word = this.resCategoriesData[values[0]].children[values[1]] ? ' - ' + this.resCategoriesData[values[0]].children[values[1]].name : '';
        },
        showPicker() {
            this.show_picker = true;
            if (this.pagination.cid1) {
                this.columns[0].defaultIndex = this.vid1;
                let name = this.resCategoriesData[this.vid1].name;
                console.log(name)
                this.columns[1].values = this.categoriesData[name];
            }
            if (this.pagination.cid2) {
                this.columns[1].defaultIndex = this.vid2;
            }
        },
        ScrollToBottomEvt() {
            const el = document.getElementById("content");
            const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
            if (toBottom < 10) {
                this.getData(true);
            }
        },
        resetData() {
            this.list = [];
            this.pagination.page = 1;
            this.over = false;
        },
        getCategoryList() {
            this.$api.Exam.CategoryList().then((res) => {
                let all = {id: 0, name: '全部', children: []}
                res.data.unshift(all);
                console.log(res.data)
                this.resCategoriesData = res.data
                this.loading = false;
                let categoriesData = {};
                let i = 0;
                let first = '';
                res.data.forEach((item) => {
                    item.children.unshift(all);
                    if (i == 0) {
                        first = item.name;
                    }
                    categoriesData[item.name] = [];
                    i++;
                    item.children.forEach((cate)=>{
                        categoriesData[item.name].push(cate.name);
                    })
                });
                this.categoriesData = categoriesData;
                console.log(categoriesData)
                this.columns = [
                    {
                        values: Object.keys(categoriesData),
                        className: 'cid1'
                    },
                    {
                        values: categoriesData[first],
                        className: 'cid2'
                    }
                ];
            });
        },
        getData(more = false) {
            if (this.over) {
                return;
            }
            if (this.loading) {
                return;
            }
            if (more) {
                this.pagination.page++;
            }
            this.loading = true;
            this.$api.Exam.PaperList(this.pagination).then((res) => {
                let list = res.data.records;
                if (list.length > 0) {
                    this.list.push(...list);
                }
                setTimeout(() => {
                    this.loading = false;
                    if (list.length < this.pagination.size) {
                        this.over = true;
                    }
                }, 200);
                // if (this.categoryFirst) {
                //     this.resCategoriesData = res.data.categories
                //     this.loading = false;
                //     let categoriesData = {};
                //     let i = 0;
                //     let first = '';
                //     res.data.categories.forEach((item) => {
                //         if (i == 0) {
                //             first = item.name;
                //         }
                //         categoriesData[item.name] = [];
                //         i++;
                //         item.children.forEach((cate)=>{
                //             categoriesData[item.name].push(cate.name);
                //         })
                //     });
                //     this.categoriesData = categoriesData;
                //     this.columns = [
                //                     {
                //                         values: Object.keys(categoriesData),
                //                         className: 'cid1'
                //                     },
                //                     {
                //                         values: categoriesData[first],
                //                         className: 'cid2'
                //                     }
                //                 ];
                //     this.categoryFirst = false;
                // }
            });
        },
        goDetail(id) {
            console.log(id)
            this.$router.push({ name: "AnswerExamsDetail", query: { id: id } });
        }
    }
}
</script>
<style lang="less" scoped>
.filter-box{
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    line-height: 30px;
    padding: 16px 16px;
    position: fixed;
    top: 44px;
    z-index: 999;
    .select{
        flex: 1;
        height: auto;
        position: relative;
        font-size: 15px;
        font-weight: 400;
        display: flex;
        align-items: center;
        .icon{
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }
    }
}
.picker {
    width: 100%;
    position: fixed;
    bottom: 0;
}
.paper{
    padding: 0 16px;
    margin-top: 120px;
    .paper-item {
        position: relative;
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        margin-bottom: 22px;
        .paper-item-comp {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 126px;
            padding: 25px 16px 22px 16px;
            box-shadow: 0px 4px 8px 0px hsla(0,0%,40%,.1);
            border-radius: 8px;
            .isenter{
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 19px;
                background: rgba(60,167,250,.1);
                border-radius: 0px 8px 0px 8px;
                font-size: 13px;
                font-weight: 400;
                color: #3ca7fa;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .line1 {
                display: flex;
                img{
                    width: 30px;
                    height: 30px;
                }
                .title {
                    flex: 1;
                    font-size: 17px;
                    font-weight: 500;
                    color: #333;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-left: 10px;
                }
            }
            .line2 {
                display: flex;
                 .word{
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                    margin-top: 40px;
                }
                .number{
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                    margin-top: 40px;
                }
            }
            .lock{
                position: absolute;
                bottom: 22px;
                right: 16px;
                width: 16px;
                height: 16px;
                img{
                    width: 16px;
                    height: 16px;
                    border-radius: 0px;
                    overflow: visible;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out 0s;
                    background-color: transparent;
                }
            }
        }
    }
}
.none {
    margin-top: 120px;
}
.drop {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.loading{
    width: 100%; 
    text-align:center; 
    margin-top:10px;
}
</style>